<template>
	<div v-if="!done">
		<a-layout class="page-wrapper">
			<a-layout-content class="main-content">
				<OfflineAlert />
				<LoadingProgress
					@done="
						() => {
							done = true
						}
					"
				/>
			</a-layout-content>
		</a-layout>
	</div>
	<div v-else-if="done && !isLoadingMerchants">
		<a-layout class="page-wrapper">
			<BasicHeader />
			<a-layout class="container">
				<a-layout-sider
					v-model="collapsed"
					:collapsible="true"
					:trigger="null"
					class="sider-container"
					width="230px"
				>
					<div class="menu-wrapper">
						<SideMenu :menu-list="menuList" />
					</div>
				</a-layout-sider>
				<a-layout class="content-container">
					<a-row style="height: 100%;">
						<a-layout-content class="main-content">
							<Breadcrumb />
							<OfflineAlert />
							<AlertField />
							<router-view />
						</a-layout-content>
					</a-row>
					<a-row>
						<BasicFooter />
					</a-row>
				</a-layout>
			</a-layout>
		</a-layout>
	</div>
</template>

<script>
import {
	BasicHeader,
	BasicFooter,
	SideMenu,
	Breadcrumb,
	AlertField,
	LoadingProgress,
} from '../components'
import { OfflineAlert } from '@/modules/core/components'
import { menuList } from '../config'
import { mapActions, mapGetters } from 'vuex'

export default {
	name: 'BasicLayout',
	components: {
		BasicHeader,
		BasicFooter,
		SideMenu,
		Breadcrumb,
		AlertField,
		LoadingProgress,
		OfflineAlert,
	},
	data: function () {
		return {
			menuList: menuList,
			done: false,
		}
	},
	computed: {
		...mapGetters({
			isLoadingMerchants: 'merchants/isLoadingMerchants',
			collapsed: 'ux/collapsed',
			onlineStatus: 'ux/onlineStatus',
			merchants: 'merchants/merchants',
		}),
	},
	watch: {
		onlineStatus(online) {
			if (online && this.merchants.length === 0) this.getData()
		},
	},
	beforeMount() {
		if (this.onlineStatus) this.getData()
	},
	methods: {
		...mapActions('merchants', ['fetchMerchants']),
		...mapActions('account', ['getUser']),
		getData() {
			this.fetchMerchants()
			this.getUser()
		},
	},
}
</script>

<style lang="less" scoped>
.page-wrapper {
	padding: 0;
	width: 100%;
	min-height: 100%;
	min-height: 100vh;

	.container {
		margin: auto;
		width: 100%;
		height: 100%;
		padding-top: 65px;
	}

	.sider-container {
		clear: both;
		background: #fff;
		max-width: 256px;

		.menu-wrapper {
			border-right: 1px solid hsv(0, 0, 85%);
			height: 90%;
			margin-top: 24px;
		}
	}

	.main-content {
		margin: 0px auto;
		padding: 0px 40px;
		width: 100%;
		max-width: 1320px;
		display: flex;
		flex-direction: column;
	}
}
</style>
