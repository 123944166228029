import { render, staticRenderFns } from "./basicLayout.vue?vue&type=template&id=26f63c3c&scoped=true&"
import script from "./basicLayout.vue?vue&type=script&lang=js&"
export * from "./basicLayout.vue?vue&type=script&lang=js&"
import style0 from "./basicLayout.vue?vue&type=style&index=0&id=26f63c3c&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "26f63c3c",
  null
  
)

export default component.exports