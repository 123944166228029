export const reportsMenuList = [
	{
		name: 'Financeiro',
		pathName: 'FinancialReport',
		path: '/reports/financial',
		icon: 'area-chart',
	},
	{
		name: 'Faturas',
		pathName: 'InvoicesReport',
		path: '/reports/invoices',
		icon: 'bar-chart',
	},
	{
		name: 'Avaliações',
		pathName: 'RatingsReport',
		path: '/reports/ratings',
		icon: 'star',
	},
]
