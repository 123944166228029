export const menuList = [
	{
		name: 'Pedidos',
		path: '/home/orders',
		icon: 'form',
		authorization: ['OWNER', 'MANAGER', 'WAITER'],
	},
	{
		name: 'Mesas',
		path: '/home/tables',
		icon: 'border',
		authorization: ['OWNER', 'MANAGER', 'WAITER'],
	},
	{
		name: 'Histórico',
		path: '/home/history',
		icon: 'history',
		authorization: ['OWNER', 'MANAGER', 'WAITER'],
	},
	{
		name: 'Cardápio',
		path: '/home/menu',
		icon: 'profile',
		authorization: ['OWNER', 'MANAGER'],
	},
	{
		name: 'Produtos',
		path: '/home/products',
		icon: 'appstore',
		authorization: ['OWNER', 'MANAGER'],
	},
	{
		name: 'Grupos operacionais',
		path: '/home/groups',
		icon: 'hdd',
		authorization: ['OWNER', 'MANAGER'],
	},
	{
		name: 'Relatórios',
		path: '/home/reports',
		icon: 'pie-chart',
		authorization: ['OWNER', 'MANAGER'],
	},
	{
		name: 'Configurações',
		path: '/home/settings',
		icon: 'setting',
		authorization: ['OWNER', 'MANAGER'],
	},
	{
		name: 'Ajuda',
		path: '/home/help',
		icon: 'question-circle',
		authorization: ['OWNER', 'MANAGER', 'WAITER'],
	},
]
