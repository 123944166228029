export const settingsMenuList = [
	{
		name: 'Apresentação',
		pathName: 'merchantSettings',
		path: '/settings/merchant',
		icon: 'appstore',
	},
	{
		name: 'Funcionamento',
		pathName: 'OperationSettings',
		path: '/settings/operation',
		icon: 'clock-circle',
	},
	{
		name: 'Fotos',
		pathName: 'ImagesSettings',
		path: '/settings/images',
		icon: 'picture',
	},
	{
		name: 'Membros',
		pathName: 'MembersSettings',
		path: '/settings/members',
		icon: 'team',
	},
	{
		name: 'Formas de pagamento',
		pathName: 'PaymentsSettings',
		path: '/settings/payments',
		icon: 'credit-card',
	},
	{
		name: 'Contrato',
		pathName: 'CompanySettings',
		path: '/settings/contract',
		icon: 'file',
	},
]
